import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import la from './locales/la.json';
import ka from './locales/ka.json';
import esp from './locales/esp.json';
import ru from './locales/ru.json';
import pl from './locales/pl.json';
import hr from './locales/hr.json';
import cs from './locales/cs.json';
import et from './locales/et.json';
import lt from './locales/lt.json';
import pt from './locales/pt.json';
import hi from './locales/hi.json';
import hu from './locales/hu.json';
import ja from './locales/ja.json';
import zh from './locales/zh-CN.json';
import vi from './locales/vi-VN.json';
import ar from './locales/ar.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import de from './locales/de.json';

const resources = {
  en: { translation: en },
  la: {
    translation: la,
  },
  ka: {
    translation: ka,
  },
  esp: {
    translation: esp,
  },
  ru: {
    translation: ru,
  },
  pl: {
    translation: pl,
  },
  hr: {
    translation: hr,
  },
  cs: {
    translation: cs,
  },
  et: {
    translation: et,
  },
  lt: {
    translation: lt,
  },
  pt: {
    translation: pt,
  },
  hi: {
    translation: hi,
  },
  hu: {
    translation: hu,
  },
  ja: {
    translation: ja,
  },
  'zh-CN': {
    translation: zh,
  },
  'vi-VN': {
    translation: vi,
  },
  ar: {
    translation: ar,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  de: {
    translation: de,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
