import { Avatar, AvatarGroup, Box, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';
import { FC, Fragment } from 'react';
import { Team, TournamentTeamMember } from 'src/__generated__/graphql';
import { formatSeed } from 'src/constants';
import TeamComponent from './TeamComponent';
import GetFlags from 'src/utils/getFlags';

interface BaseProps {
  users: TournamentTeamMember[];
  team?: Team;
  showSeed?: boolean;
  isShortName?: boolean;
  highlightWinner?: boolean;
  showAvatar?: boolean;
  showTeamMembers?: boolean;
  group?: any;
  shouldDisplayDupr?: boolean;
}

interface SeedProps {
  seed: string;
  assignedSegment: string;
  additionalSegment: string;
  segment: string;
}

type TeamMembersProps = BaseProps & (BaseProps['showSeed'] extends true ? SeedProps : Partial<SeedProps>);

const TeamMembers: FC<TeamMembersProps> = ({
  users,
  team,
  showSeed,
  isShortName,
  showTeamMembers,
  highlightWinner,
  showAvatar,
  seed,
  assignedSegment,
  additionalSegment,
  segment,
  shouldDisplayDupr = false,
}) => {
  return (
    <Fragment>
      {team?.title ? (
        <TeamComponent team={team} users={users} highlightWinner={highlightWinner} showTeamMembers={showTeamMembers} shouldDisplayDupr={shouldDisplayDupr} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          {showAvatar && (
            <AvatarGroup spacing={24}>
              {users?.map((user) => (
                <Avatar alt={user?.user?.name} src={user?.user?.avatar} />
              ))}
            </AvatarGroup>
          )}
          <Typography
            variant="font18"
            sx={{
              textTransform: 'uppercase',
              textAlign: 'left',
              mr: '8px',
              display: 'flex !important',
              flexDirection: 'column',
              gap: '8px',
              width: 'max-content',
              fontWeight: highlightWinner ? 600 : 500,
            }}
          >
            {users?.map((user, index, usersArray) => (
              <Box
                key={user.id}
                component="span"
                sx={{ cursor: 'pointer', display: 'flex', gap: '5px' }}
                onClick={() => window.open(`/athlete/${user?.user?.id}/${user?.user?.name}-${user?.user?.surname}?tab=overview`, '_blank')}
              >
                <GetFlags country={user?.user?.nation} style={{ width: '15px', height: '15px' }} />

                {isShortName ? (
                  <Typography
                    variant="font18"
                    sx={{
                      fontWeight: highlightWinner ? 600 : 500,
                    }}
                  >
                    {user?.user?.name?.charAt(0) + '. ' + user?.user?.surname}
                  </Typography>
                ) : (
                  <Typography
                    variant="font18"
                    sx={{
                      fontWeight: highlightWinner ? 600 : 500,
                    }}
                  >
                    {`${user?.user?.name || ''} ${user?.user?.surname || ''}`}
                  </Typography>
                )}
                <b>
                  {showSeed &&
                    index === usersArray?.length - 1 &&
                    formatSeed(
                      {
                        seed: seed,
                        assignedSegment: assignedSegment,
                        additionalSegment: additionalSegment,
                      },
                      segment,
                    )}
                </b>
              </Box>
            ))}
          </Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default TeamMembers;
