import { gql } from '@apollo/client';

const TOURNAMENTS = gql`
  query allTournaments($filter: ListTournamentInput, $page: Int, $sports: [Int!], $limit: Int, $federationId: Int, $platform: Int) {
    alltournament(filter: $filter, page: $page, limit: $limit, federationId: $federationId, sports: $sports, platform: $platform) {
      tournaments {
        id
        title
        tour
        address
        startDate
        endDate
        entryDeadline
        createdAt
        country
        city
        logo
        coverPhoto
        closeRegistration
        paymentMethod
        status
        isFeatured
        timeZone
        registerLink
        registrationStartDate
        league {
          id
          league {
            id
            owner {
              id
              federation {
                id
                organizationName
                organizationType
                organizationAbbreviation
                logo
              }
            }
            title
            enableLicense
            sports {
              id
              icon
              title
            }
            abbreviation
          }
        }
        tournamentCategory {
          isRegistered
          category {
            name
            type
            gender
            status
            id
            ageLimit
            ageType
            ageTo
            ageFrom
            skillsLevel
            ageGroup
          }
          status
          id
          fee
          currency
        }
        organizer {
          organizationName
          organizationEmail
          isOrganization
          member {
            name
            id
            surname
            avatar
          }
        }
        referee {
          referee {
            name
          }
        }
        registrationRequests {
          id
        }
      }
      total
    }
  }
`;

export default TOURNAMENTS;
