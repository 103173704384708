import { gql } from 'src/__generated__';

const GET_TOURNAMENT_DETAILS = gql(`
  query tournamentDetailById($id: Int!, $userId: Int) {
    tournamentDetailById(id: $id, userId: $userId) {
      id
      title
      Album{
        id
        thumbnail
        albumTags
        albumCustomEvent
        date
        createdAt
        title
        isSpecificTournament
        media{
          id
        }
        
      }
      discounts {
        numberOfCategories
        discount
      }
      club {
        id
        logo
        clubName
      }
      isManagerPayingServiceFee
      isPayForMyselfEnabled
      events
      isDuprRatingEnabled
      isDuprRequired
      tour
      coverPhoto
      startDate
      endDate
      registrationStartDate
      entryDeadline
      description
      license
      timeZone
      termsAndConditions
      customInfoMessage
      customSuccessMessage
      closeRegistration
      partnerSearch
      country
      address
      city
      entryFee
      registerLink
      withdrawlDeadline
      currency
      createdAt
      termsAndConditions
      type
      registerLink
      regulations
      paymentMethod
      logo
      timeControl
      showOOP
      poster
      tournamentRankings {
        id
      }
      organizer {
        organizationName
        organizationEmail
        organizationLogo
        organizationPhone
        isOrganization
        member {
          email
          name
          id
          avatar
          surname
          instagram
          facebook
          phone
        }
      }
      league {
        id
        league {
          id
          enableLicense
          logo
          owner {
            id
            federation {
              id
              logo
            }
          }
          sports {
            id
            icon
            title
            resourceTitle
            displayLiveStandings
            displayOrderPlay
            displayListResults
            displayPendingResults
            displayTournamentResults
            displayGroups
            displayDraws
            enableStages
            enableCourts
            enableDiscipline
            isEnablePairings
            isEnablePairingStandings
            isDisplaySeeds
            isDisplaySegments
            isDisplayAthleteChessTitle
          }
          title
        }
      }
      tournamentCategory {
        additionalQuestions {
          id
          question
          fieldType
          fieldOptions
          answer
          askEachAthlete
        }
        status
        isRegistered
        paymentStatus
        onlinePayments
        category {
          enableLicense
          license {
            id
            license {
              id
              title
            }
          }
          isDisplayClub
          isDisplayCity
          timeControl
          name
          type
          gender
          status
          id
          ageLimit
          ageType
          ageTo
          ageFrom
          skillsLevel
          ageGroup
        }
        id
        fee
        onsiteFee
        currency
        method
        time
        timePerMove
        timeAfterMove
        extraTime
        pairing
      }
      draws {
        id
      }
      groups {
        id
      }
      pairings {
        id
        hideStandings
      }
      isHideEntries
      liveStandings {
        id
      }
      addressCoordinates
      referee {
        id
        referee {
          id
          name
        }
      }
      additionalReferee {
        id
        referee {
          id
          name
        }
      }

      facts {
        id
        answer
        factSheet {
          id
          fieldTitle
          fieldType
          options
        }
      }
    }
  }
`);

export default GET_TOURNAMENT_DETAILS;
