import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Container, useMediaQuery } from '@material-ui/core';
import { experimentalStyled, Theme } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import LanguagePopover from './LanguagePopover';
import EventMenu from './event-menu';
import useAuth from 'src/hooks/useAuth';
import SportSelector from './SportSelector';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AuthContext from 'src/contexts/JWTContext';
import WelcomeModal from '../widgets/modals/Welcome/WelcomeModal';
import RecoverModal from '../widgets/modals/Welcome/RecoverModal';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { CreditCard, MenuOpenOutlined } from '@material-ui/icons';
import AuthModal, { Modals } from 'src/components/CustomComponents/CustomModal/Modals/AuthModal/AuthModal';
import ServerCrashModal from 'src/components/CustomComponents/CustomModal/Modals/ServerCrashModal';
import CustomAvatar from '../CustomComponents/CustomAvatar';
import ProfileInActive from '../CustomComponents/CustomModal/Modals/profileInActive';
import { getPlatformId } from 'src/constants';
import TournamentRegistrationModal from './tournamentRegistrations/TournamentRegistrationModal';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  onProfileSidebarOpen?: () => void;
  onMobileClose: () => void;
  openMobile: boolean;
  setRightDrawerOpen;
}

export const NAVBAR_HEIGHT = { lg: 60, sm: 50 };

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: 'white',
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  borderBottom: '1px solid #EFEFF0',
  zIndex: 0,
  position: 'inherit',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  height: NAVBAR_HEIGHT.lg + 'px',
  [theme.breakpoints.down('md')]: {
    height: NAVBAR_HEIGHT.sm + 'px',
  },
}));

const DashboardNavbar: FC<DashboardNavbarProps> = ({ setRightDrawerOpen, ...props }) => {
  const { onSidebarMobileOpen, onProfileSidebarOpen, onMobileClose, openMobile, ...other } = props;
  const auth = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    loginModalOpen,
    signupModalOpen,
    isServerCrashModalOpen,
    setServerCrashModalOpen,
    forgetModalOpen,
    welcomeModalOpen,
    recoverModalOpen,
    setRecoverModalOpen,
    setWelcomeModalOpen,
    setSignupModalOpen,
    setLoginModalOpen,
    resetModalOpen,
  } = useContext(AuthContext);
  // const [isModalOpen, setIsModalOpen] = useState<boolean>();
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const modalStates: Record<Modals, boolean> = {
    login: loginModalOpen,
    register: signupModalOpen,
    forget: forgetModalOpen,
    reset: resetModalOpen,
  };

  const isModal = (key: string): key is Modals => {
    return key in modalStates;
  };

  const currentModal = Object.keys(modalStates).find((key): key is Modals => isModal(key) && modalStates[key]) || 'login';
  // useEffect(() => {
  //   const platformId = getPlatformId();

  //   if (location.pathname?.includes('athlete') && platformId === 15) {
  //     setIsModalOpen(true);
  //   } else {
  //     setIsModalOpen(false);
  //   }
  // }, [location.pathname]);
  return (
    <React.Fragment>
      <DashboardNavbarRoot {...other} id="platform-navbar">
        <Container maxWidth={false} sx={{ maxWidth: '1920px' }}>
          <Toolbar
            sx={{
              minHeight: '10px !important',
              padding: '0 !important',
              alignItems: { lg: 'center', xs: 'center' },
              justifyContent: 'space-between',
              width: '100%',
              gap: '20px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', overflow: 'hidden' }}>
              {isTablet && (
                <>
                  <CustomButton size="auto" variant="text" shape="circle" sx={{ justifyContent: 'start' }} onClick={() => navigate('/')}>
                    <img alt="logo" src={auth?.platformData?.lightLogo || process.env.REACT_APP_LOGO_ICON || '/images/updated/small.svg'} style={{ width: '22px', height: '22px' }} />
                  </CustomButton>
                  <LanguagePopover />
                </>
              )}
              {!isTablet && <SportSelector />}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <EventMenu isManager={auth?.isManager} />
              {auth?.user && (
                <CustomButton
                  variant="secondary"
                  size="small"
                  onClick={() => {
                    auth?.user ? navigate('/get-licence') : auth?.setLoginModalOpen(true);
                  }}
                  sx={{ gap: '4px' }}
                >
                  <CreditCard sx={{ fontSize: '16px' }} /> {t('Buy licence')}
                </CustomButton>
              )}
              {!isTablet && <LanguagePopover />}
              {!auth?.user && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <CustomButton variant="secondary" size="small" onClick={() => setLoginModalOpen(true)}>
                    {t('Log in')}
                  </CustomButton>
                  <CustomButton variant="primary" size="small" onClick={() => setSignupModalOpen(true)}>
                    {t('Sign up')}
                  </CustomButton>
                </Box>
              )}
              {isTablet && auth.user && (
                <CustomButton
                  size="auto"
                  variant="text"
                  sx={{ maxWidth: 'fit-content', maxHeight: 'fit-content', minWidth: 'fit-content', minHeight: 'fit-content', padding: 0, gap: '4px' }}
                  onClick={() => setRightDrawerOpen(true)}
                >
                  <CustomAvatar size={28} src={auth.user?.avatar} seed={auth.user?.name + ' ' + auth.user?.surname} />
                  <MenuOpenOutlined />
                </CustomButton>
              )}
            </Box>
          </Toolbar>
        </Container>
      </DashboardNavbarRoot>
      <AuthModal modal={currentModal} openOverride={loginModalOpen || signupModalOpen || forgetModalOpen || resetModalOpen} />
      <WelcomeModal open={welcomeModalOpen} setOpen={setWelcomeModalOpen} />
      {/* <ProfileInActive open={isModalOpen} onClose={() => setIsModalOpen(false)} /> */}
      <RecoverModal open={recoverModalOpen} setOpen={setRecoverModalOpen} />
      <ServerCrashModal open={isServerCrashModalOpen} onClose={() => setServerCrashModalOpen(false)} />
      {auth?.isTournamentRegModalOpen && <TournamentRegistrationModal open={auth?.isTournamentRegModalOpen} handleClose={() => auth?.setTournamentRegistrationModalOpen(false)} />}
    </React.Fragment>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  onProfileSidebarOpen: PropTypes.func,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardNavbar;
