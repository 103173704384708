import { Payments as CashIcon, CreditCard as CreditCardIcon } from '@material-ui/icons';
import { getCurrencySymbol } from 'src/utils/helperFunction';
import React from 'react';
import { height } from '@material-ui/system';
import { Box, Typography } from '@material-ui/core';

export function CalculateFEE(feeDetails, feeObj) {
  const { fee, onsiteFee, currency, method } = feeObj;
  const getIcon = (method) => {
    switch (method) {
      case 'online':
        return CreditCardIcon;
      case 'onsite':
        return CashIcon;
      case 'both':
        return feeDetails?.onlineDiscountAmount < feeDetails?.onsiteDiscountAmount ? CreditCardIcon : CashIcon;
      default:
        return CreditCardIcon; // Default to online icon
    }
  };
  const getFee = (method, feeDetails) => {
    switch (method) {
      case 'online':
        return feeDetails?.discount > 0 ? feeDetails?.onlineDiscountAmount : 0;
      case 'onsite':
        return feeDetails?.onSiteDiscount > 0 ? feeDetails?.onsiteDiscountAmount : 0;
      case 'both':
        return feeDetails?.discount > 0 && feeDetails?.onsiteDiscountAmount > 0
          ? feeDetails?.onlineDiscountAmount < feeDetails?.onsiteDiscountAmount
            ? feeDetails?.onlineDiscountAmount
            : feeDetails?.onsiteDiscountAmount
          : 0;
      default:
        return feeDetails?.discount > 0 ? feeDetails?.onlineDiscountAmount : 0; // Default to online icon
    }
  };
  const formatFee = (fee, value, Icon) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon style={{ verticalAlign: 'text-top', marginRight: '3px', height: '15px', width: '15px' }} />
        {getCurrencySymbol(currency)}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
          {' '}
          <Typography
            sx={{
              textDecoration: fee > 0 ? 'line-through' : 'none',
              textDecorationThickness: fee > 0 ? '1.5px' : 'initial', // Adjust thickness for bold effect

              textDecorationColor: fee > 0 ? process.env.REACT_APP_PRIMARY_COLOR : 'inherit', // Change 'red' to your desired color
            }}
          >
            {value}
          </Typography>
          <Typography> {fee > 0 ? fee : ''}</Typography>
        </Box>
      </Box>
    );
  };
  if ((!feeDetails?.originalAmount || feeDetails?.originalAmount === 0) && (!feeDetails?.originalOnsiteAmount || feeDetails?.originalOnsiteAmount === 0)) {
    return 'Free';
  }
  if (feeDetails?.originalAmount && feeDetails?.originalOnsiteAmount) {
    if (feeDetails?.originalAmount === feeDetails?.originalOnsiteAmount) {
      return formatFee(getFee(method, feeDetails), feeDetails?.originalAmount, getIcon(method));
    } else {
      return (
        <>
          {formatFee(getFee(method, feeDetails), Math.min(feeDetails?.originalAmount, feeDetails?.originalOnsiteAmount), getIcon(method))}
          {' - '}
          {formatFee(getFee(method, feeDetails), Math.max(feeDetails?.originalAmount, feeDetails?.originalOnsiteAmount), method === 'both' ? CashIcon : getIcon(method))}
        </>
      );
    }
  } else if (feeDetails?.originalAmount && !feeDetails?.originalOnsiteAmount) {
    return formatFee(getFee(method, feeDetails), feeDetails?.originalAmount, getIcon(method));
  } else if (feeDetails?.originalOnsiteAmount && !feeDetails?.originalAmount) {
    return formatFee(getFee(method, feeDetails), feeDetails?.originalOnsiteAmount, getIcon(method));
  } else {
    return '';
  }
}
export function calculateFee(feeObj) {
  const { fee, onsiteFee, currency, method } = feeObj;

  const getIcon = (method) => {
    switch (method) {
      case 'online':
        return CreditCardIcon;
      case 'onsite':
        return CashIcon;
      case 'both':
        return fee < onsiteFee ? CreditCardIcon : CashIcon;
      default:
        return CreditCardIcon;
    }
  };

  const formatFee = (value, Icon) => (
    <>
      <Icon style={{ verticalAlign: 'text-top', marginRight: '3px', height: '15px', width: '15px' }} />
      {getCurrencySymbol(currency)}
      {value}
    </>
  );
  if ((!fee || fee === 0) && (!onsiteFee || onsiteFee === 0)) {
    return 'Free';
  }
  if (fee && onsiteFee) {
    if (fee === onsiteFee) {
      return formatFee(fee, getIcon(method));
    } else {
      return (
        <>
          {formatFee(Math.min(fee, onsiteFee), fee < onsiteFee ? CreditCardIcon : CashIcon)}
          {' - '}
          {formatFee(Math.max(fee, onsiteFee), fee >= onsiteFee ? CreditCardIcon : CashIcon)}
        </>
      );
    }
  } else if (fee && !onsiteFee) {
    return formatFee(fee, getIcon(method));
  } else if (onsiteFee && !fee) {
    return formatFee(onsiteFee, getIcon(method));
  } else {
    return '';
  }
}
export function calculateFeeNew(feeObj, additionalFee, isDoubleAmount) {
  const { fee, onsiteFee, currency } = feeObj;
  const updatedFee = isDoubleAmount ? fee * 2 + additionalFee : fee + additionalFee;
  const updatedOnsiteFee = isDoubleAmount ? onsiteFee * 2 + additionalFee : onsiteFee + additionalFee;

  const formatFee = (value, Icon) => (
    <>
      <Icon style={{ verticalAlign: 'middle', marginRight: '4px', height: '15px', width: '15px' }} />
      {getCurrencySymbol(currency)}
      {value}
    </>
  );

  if (updatedFee && updatedOnsiteFee) {
    if (updatedFee === updatedOnsiteFee) {
      return formatFee(updatedFee, CreditCardIcon);
    } else {
      return (
        <>
          {formatFee(Math.min(updatedFee, updatedOnsiteFee), updatedFee < updatedOnsiteFee ? CreditCardIcon : CashIcon)} -{' '}
          {formatFee(Math.max(updatedFee, updatedOnsiteFee), updatedFee < updatedOnsiteFee ? CashIcon : CreditCardIcon)}
        </>
      );
    }
  } else if (updatedFee && !updatedOnsiteFee) {
    return formatFee(updatedFee, CreditCardIcon);
  } else if (updatedOnsiteFee && !updatedFee) {
    return formatFee(updatedOnsiteFee, CashIcon);
  } else {
    return '';
  }
}
