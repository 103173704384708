import type { FC } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Box, Grid, Typography, Divider, InputAdornment, CircularProgress, Autocomplete, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useLazyQuery, useQuery } from '@apollo/client';
import { CLUBS, GET_FIDE_RANKINGS } from 'src/graphql/queries';
import { Gender, getPlatformId } from 'src/constants';
import MobileDatePicker from '@material-ui/lab/MobileDatePicker';
import PhoneInput from 'react-phone-number-input';
import countries from 'src/utils/countries';
import { useRegisterContext } from 'src/contexts/RegisterContext';
import debounce from 'lodash/debounce';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CustomFormInput from 'src/components/CustomComponents/CustomFormInput';
import CustomAutocomplete from 'src/components/CustomComponents/CustomAutocomplete';
import CustomSelect from 'src/components/CustomComponents/CustomSelect';
import CustomButton, { getButtonStyles } from 'src/components/CustomComponents/CustomButton';
import useDebouncedTerm from 'src/hooks/useDebouncedTerm';

const useStyles = makeStyles(() => ({
  passwordContainer: {
    position: 'relative',
  },
  passwordToggleBtn: {
    position: 'absolute',
    top: '29px',
    right: '18px',
    cursor: 'pointer',
    border: 'none',
    background: 'transparent',
  },
  label: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    marginBottom: '13px',
  },
  textfield: {
    // height: "40px",
    flexShrink: 0,
    borderRadius: '8px',

    background: '#FFF',

    '& .MuiOutlinedInput-root': {
      // height: "37px",
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  labelfield: {
    color: '#86909F',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  submitBtn: {
    background: '#0A2540',
    borderRadius: '4px',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
  },
  loginActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },
}));

const AboutSection: FC<any> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [Clubs, setClubs] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const debouncedSearch = useDebouncedTerm(searchTitle, 500);

  const { registerObject, updateRegisterObject } = useRegisterContext();
  const [user, setUser] = useState<any>({});
  const [getUser, { data: userData, loading }] = useLazyQuery(GET_FIDE_RANKINGS);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // const filteredCountries = countries?.filter((country) => !(getPlatformId() === 1 && country.value === 'Russia'));

  const { loading: clubsLoading, data: clubs } = useQuery(CLUBS, {
    variables: {
      page: page + 1,
      limit: 25,
      filter: {
        ...(debouncedSearch && { clubName: debouncedSearch }),
        platform: getPlatformId(),
        status: 'Active',
      },
    },
    onCompleted: (data) => {
      const CLUBSDATA = data?.clubs?.clubs;
      setHasMore(CLUBSDATA?.length > 0);
      setClubs((prevClub) => {
        const newClubs = CLUBSDATA?.filter((club) => !prevClub.find((prev) => prev?.id === club?.id) && club?.clubMainAdministrator?.id !== user?.id);
        return [...prevClub, ...newClubs];
      });
      // setClubs((prevClub) => {
      //   const newClubs = CLUBSDATA?.filter((club) => !prevClub.find((prev) => prev?.id === club?.id));
      //   return [...prevClub, ...newClubs];
      // });
    },
    fetchPolicy: 'cache-and-network',
  });

  const debouncedFilter = useCallback(
    debounce(async (fideId) => {
      try {
        const res = await getUser({
          variables: {
            fideId: fideId,
          },
        });
        const variables = {
          fideId: fideId,
        };
        updateRegisterObject({ ...registerObject, ...variables });
        if (res?.data) {
          toast.success(t('FIDE ID found'));
          setUser(res?.data?.getPlayerFideRanking);
        } else {
          setUser('');
          const variables = {
            gender: '',
            citizenship: '',
            dob: '',
            fideId: fideId,
          };
          updateRegisterObject({ ...registerObject, ...variables });
          toast.error(t('FIDE ID Not found'));
        }
      } catch (error) {
        const variables = {
          gender: '',
          citizenship: '',
          dob: '',
          fideId: fideId,
        };
        updateRegisterObject({ ...registerObject, ...variables });
        setUser('');
        toast.error(t('FIDE ID Not found'));
      }
    }, 1000),
    [],
  );
  const observer: any = useRef();

  const lastOptionElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore && !clubsLoading) {
          setPage((page) => page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [clubsLoading],
  );
  useEffect(() => {
    if (Object?.keys(user)?.length) {
      const dob = user?.birth_year ? moment({ year: user.birth_year, month: 0, date: 10 }).format('YYYY-MM-DD') : null;
      const variables = {
        gender: user?.sex,
        citizenship: user?.federation,
        dob: dob,
      };
      updateRegisterObject({ ...registerObject, ...variables });
    }
  }, [user]);
  const yupObject = {
    // nation: yup.string().max(255).required("country is Required"),
    gender: yup.string().max(255).required(t('Gender is required')),
    phone: yup.string().required(t('Phone is required')),
    citizenship: yup.string().required(t('Citizenship is required')),
    // fideId: yup.string().required("FIDE ID is required"),
    dob: yup.date().required(t('Dob is required')),
  };
  const validation = yupObject;
  const getFideUser = (e) => {
    if (e?.target?.value) {
      debouncedFilter(e.target.value);
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        height: 'max-content',
        maxHeight: '100%',
        minHeight: '474px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          height: '100%',
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ color: '#0A2540', lineHeight: '20px' }}>
            {t('Tell us more about you')}
          </Typography>
          <Typography variant="font21" sx={{ color: '#0A2540', lineHeight: '20px', opacity: '35%' }}>
            {t('More information about yourself')}
          </Typography>
          <Box sx={{ mt: 1.5 }}>
            <Divider />
          </Box>
        </Box>
        <Box className={classes.loginActionContainer} sx={{ height: '100%' }}>
          <Formik
            enableReinitialize
            initialValues={{
              phone: registerObject?.phone || '',
              gender: registerObject?.gender || '',
              citizenship: registerObject?.citizenship || '',
              dob: registerObject?.dob || '',
              fideId: registerObject?.fideId,
              clubRequests: registerObject?.clubRequests || [],
            }}
            validationSchema={yup.object().shape(validation)}
            onSubmit={async (values): Promise<void> => {
              const variables = {
                phone: values.phone,
                gender: values.gender,
                citizenship: values.citizenship,
                dob: values.dob,
                fideId: values.fideId,
                clubRequests: values?.clubRequests,
              };

              try {
                updateRegisterObject({ ...registerObject, ...variables });
                props.handleClick(3);
              } catch (error) {}
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, setFieldTouched, isSubmitting, touched, values }): JSX.Element => (
              <form noValidate onSubmit={handleSubmit} {...props} style={{ height: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    minHeight: '378px',
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid container spacing="8px">
                    {registerObject?.user_sports?.includes(7) && (
                      <Grid item lg={12} md={12} xs={12}>
                        <CustomFormInput
                          error={Boolean(touched.fideId && errors.fideId)}
                          helperText={touched.fideId && (errors.fideId as any)}
                          placeholder={t('Enter FIDE ID')}
                          name="fideId"
                          onChange={(e) => {
                            handleChange(e);
                            getFideUser(e);
                          }}
                          defaultValue="M"
                          value={values.fideId}
                          InputProps={{
                            endAdornment: loading ? (
                              <InputAdornment position="end">
                                <CircularProgress size={20} />
                              </InputAdornment>
                            ) : null,
                          }}
                        />
                        <Box sx={{ mt: 2 }}>
                          <Divider>
                            <Typography
                              variant="font21"
                              sx={{
                                color: 'rgba(10, 37, 64, 0.35)',
                                lineHeight: '15.6px',
                              }}
                            >
                              Or
                            </Typography>
                          </Divider>
                        </Box>
                      </Grid>
                    )}

                    <Grid item lg={6} md={6} xs={12}>
                      <PhoneInput
                        international
                        defaultCountry={getPlatformId() === 14 ? ('RU' as any) : ('LV' as any)}
                        placeholder={t('Enter phone number')}
                        value={values.phone}
                        name="phone"
                        onChange={(value) => setFieldValue('phone', value)}
                        style={{
                          ...getButtonStyles('default', 'medium', 'secondary'),
                          ...(Boolean(touched.phone && errors.phone) ? { borderColor: 'red' } : {}),
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CustomSelect
                        placeholder={t('Gender')}
                        onChange={(value) => setFieldValue('gender', value)}
                        selectedValue={values.gender}
                        error={!!(touched.gender && errors.gender)}
                        helperText={touched.gender && errors.gender ? 'Gender is required' : undefined}
                        selected={!!values.gender}
                        options={Gender.map((gen) => ({ title: gen.label, value: gen.value }))}
                        buttonProps={{
                          size: 'medium',
                          sx: { width: '100%' },
                        }}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <CustomAutocomplete
                        width="100%"
                        options={countries}
                        onChange={(event: React.ChangeEvent<{}>, { value }) => {
                          setFieldValue('citizenship', value ?? '');
                        }}
                        selectedValue={(values?.citizenship as string) ?? null}
                        placeholder={t('Representing country')}
                        error={Boolean(touched.citizenship && errors.citizenship)}
                        helperText={touched.citizenship && (errors.citizenship as any)}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <Box className="date-of-birth">
                        <MobileDatePicker
                          // label="Date of Birth"
                          inputFormat="dd/MM/yyyy"
                          onAccept={() => setFieldTouched('dob')}
                          onChange={(dob) => {
                            setFieldValue('dob', dob);
                          }}
                          // onClose={() => setFieldTouched('dob')}
                          open={open}
                          onOpen={() => setOpen(true)}
                          onClose={() => {
                            setOpen(false), setFieldTouched('dob');
                          }}
                          renderInput={({ error: _, ...props }) => (
                            <CustomFormInput
                              placeholder={t('Date of Birth')}
                              error={Boolean(touched.dob && errors.dob)}
                              helperText={touched.dob && (errors.dob as any)}
                              name="dob"
                              {...props}
                              sx={{ maxHeight: '40px !important', cursor: 'pointer !important' }}
                              size="small"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" onClick={() => setOpen(true)}>
                                    <img src="/images/updated/inputCalendar.svg" style={{ width: '14px', height: '14px' }} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                          value={values.dob}
                        />
                      </Box>
                    </Grid>
                    {getPlatformId() !== 2 && (
                      <Grid item xs={12}>
                        {/* <Typography className={classes.label}>{t('Club')}</Typography> */}

                        <Autocomplete
                          multiple
                          value={values?.clubRequests || []}
                          options={Clubs ?? []}
                          getOptionLabel={(option) => option?.clubName}
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(event: React.ChangeEvent<{}>, value: any, reason: any, option) => {
                            setFieldValue('clubRequests', value);
                          }}
                          clearIcon={false}
                          renderOption={(props, option) => (
                            <li {...props} ref={lastOptionElementRef}>
                              {option?.clubName}
                            </li>
                          )}
                          popupIcon={<img src="/images/updated/Chevron.svg" style={{ padding: '6px' }} />}
                          sx={{
                            background: '#fff',
                            zIndex: 199,
                            overflowY: 'auto',
                            '& .MuiOutlinedInput-root': {
                              minHeight: '39px',
                              paddingTop: '0px !important',
                              paddingBottom: '0px !important',
                              border: '1px solid #0a254026',
                              paddingRight: '5px !important',
                            },
                            '& .MuiOutlinedInput-input': {
                              height: '0.4375em',
                            },
                          }}
                          className={classes.textfield}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              name="user_club_id"
                              placeholder={t('Select club')}
                              variant="outlined"
                              // InputLabelProps={{
                              //   className: classes.labelfield,
                              // }}
                              onChange={(e) => {
                                if (e.target.value === '') setHasMore(true);
                                setPage(0);
                                setSearchTitle(e.target.value);
                              }}
                              className={classes.textfield}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {clubsLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={16}
                                        sx={{
                                          background: '#ffff',
                                          zIndex: '99',
                                          marginRight: '14px',
                                          borderRadius: '50%',
                                        }}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Box>
                    <CustomButton size="medium" type="submit" variant="primary" sx={{ width: '100%', mt: '8px' }}>
                      {t('Continue')}
                    </CustomButton>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutSection;
