import { Box, Typography } from '@material-ui/core';
import React from 'react';

const MatchesScores = ({ scores1, scores2, winner }) => {
  const renderScore = (score1, score2) => {
    const mainScore1Int = parseInt(score1?.mainScore ?? score1, 10);
    const mainScore2Int = parseInt(score2?.mainScore ?? score2, 10);

    return (
      <Box sx={{ paddingLeft: '1px', width: '15px', textAlign: 'center', position: 'relative' }}>
        {score1?.tieScore && (
          <sup>
            <Typography
              sx={{
                position: 'absolute',
                color: '#425466',
                fontFamily: 'Inter',
                fontSize: '7px',
                fontWeight: 500,
                lineHeight: 'normal',
                right: 1,
                top: -5,
              }}
            >
              {score1?.tieScore}
            </Typography>
          </sup>
        )}
        <Typography
          sx={{
            color: mainScore1Int > mainScore2Int ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
            fontFamily: 'Inter',
            fontSize: '11px',
            fontWeight: 500,
            lineHeight: 'normal',
          }}
        >
          {score1?.mainScore ?? score1}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', height: '32px', alignItems: 'center', justifyContent: 'center' }}>
      {scores1?.map((score1, index) => (
        <Box
          key={index}
          sx={{
            padding: '5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: index !== 0 ? '1px solid #EDF1F6' : '',
            borderBottom: '1px solid #EDF1F6',
            height: '100%',
            width: '100%',
          }}
        >
          {renderScore(score1, scores2[index])}
        </Box>
      ))}
    </Box>
  );
};

export default MatchesScores;
