import type { FC } from 'react';
import { Fragment } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles, withStyles } from '@material-ui/core';
import moment from 'moment';
import ScoresCells from './ScoreCells';
import GetFlag from 'src/utils/getFlags';
import { formatSeed } from 'src/constants';
import Loading from 'src/components/Loading';
import NoDataFound from 'src/components/dashboard/NoDataFound';
import { getShortMatchStatus } from 'src/utils/helperFunction';
import TeamMembers from 'src/components/shared/TeamMembers';
import { useTranslation } from 'react-i18next';
const Groups = [
  {
    id: '1',
    player: '1. K. VILDE / D. STORĶIS',
    flag: '/images/flag-latvia.svg',
    status: '-',
    tstamp: { num: '1', time: '21:14' },
    tstamp2: { num: '1', time: '21:14' },
    points: 2,
    SR: '2.000 (2/0)',
    PR: '1.313 (42/32)',
    place: '1',
  },
  {
    id: '2',
    player: '1. K. VILDE / D. STORĶIS',
    flag: '/images/flag-latvia.svg',
    status: '-',
    tstamp: { num: '1', time: '21:14' },
    tstamp2: { num: '1', time: '21:14' },
    points: 2,
    SR: '2.000 (2/0)',
    PR: '1.313 (42/32)',
    place: '1',
  },
  {
    id: '3',
    player: '1. K. VILDE / D. STORĶIS',
    flag: '/images/flag-latvia.svg',
    status: '-',
    tstamp: { num: '1', time: '21:14' },
    tstamp2: { num: '1', time: '21:14' },
    points: 2,
    SR: '2.000 (2/0)',
    PR: '1.313 (42/32)',
    place: '1',
  },
];

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  winner: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  loser: {
    color: 'rgba(25, 54, 96, 0.40)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  ponits: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
  },
  heading: {
    color: 'var(--bright-blue-60, rgba(27, 56, 97, 0.60))',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  scoreText: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '9px',
    },
  },
  head: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  matchNotFinish: {
    padding: '5px 0',
    // border: "1px solid rgba(25, 29, 80, 0.06)",
    // marginBottom: "8px",
    borderRadius: '6px',
  },
  tablehead: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  playerName: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
}));
const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

const GroupsListView: FC<any> = ({ groupsTableData, loading, selectedCat }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getMatchData = (groupMatches, entry1ID, entry2ID) => {
    const groupMatchData = groupMatches?.find((groupMatch) => {
      const { entry1, entry2 } = groupMatch;
      return (entry1.id === entry2ID && entry2.id === entry1ID) || (entry1.id === entry1ID && entry2.id === entry2ID);
    });

    if (groupMatchData) {
      const scoresByRound = groupMatchData.score.reduce((acc, score) => {
        const round = score.round;
        if (!acc[round]) {
          acc[round] = [];
        }
        acc[round].push(score);
        return acc;
      }, {});

      let formattedScoresString = '';

      Object.keys(scoresByRound).forEach((round) => {
        const scoresForRound = scoresByRound[round];
        const formattedScores = [];

        const entry1 = scoresForRound[0];
        const entry2 = scoresForRound[1];

        if (entry1 && entry2) {
          if (entry1.entry.id === entry1ID && entry2.entry.id === entry2ID) {
            entry1.roundFormat === 'tie'
              ? formattedScores.push(`[${entry1.score}:${entry2.score}]`)
              : entry1.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry1.score}:${entry2.score})`)
              : formattedScores.push(`${entry1.score}:${entry2.score}`);
          } else {
            entry2.roundFormat === 'tie'
              ? formattedScores.push(`[${entry2.score}:${entry1.score}]`)
              : entry2.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry2.score}:${entry1.score})`)
              : formattedScores.push(`${entry2.score}:${entry1.score}`);
          }
        }
        formattedScoresString += formattedScores.join(' ') + ' ';
      });

      // Remove the trailing space if needed
      formattedScoresString = formattedScoresString
        .trim()
        .replace(/\s+\(/g, '(') // Remove space before opening (
        .replace(/\)\s+/g, ') ') // Ensure a single space after closing )
        .replace(/\]\s+/g, '] '); // Ensure a single space after closing ]
      return { ...groupMatchData, score: formattedScoresString };
    }

    return null;
  };
  const MatchesTableCellData = (entry1ID, entry2ID, group) => {
    const updatedMatchData = getMatchData(group.matches, entry1ID, entry2ID);
    return updatedMatchData?.score?.length > 0 || updatedMatchData?.scoreType ? (
      <Fragment>
        <Typography
          sx={{
            pb: '5px',
          }}
          className={classes.scoreText}
        >
          {updatedMatchData?.winner?.id === entry1ID ? 1 : 0}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography className={classes.text} sx={{ mt: '5px', whiteSpace: 'nowrap' }}>
            {`${updatedMatchData?.score || ''} ${updatedMatchData?.scoreType ?? ''}`}
          </Typography>
        </Box>
      </Fragment>
    ) : (
      <Fragment>
        {updatedMatchData?.date && updatedMatchData?.time && (
          <Box className={classes.matchNotFinish}>
            <Typography className={classes.scoreText} sx={{ whiteSpace: 'nowrap' }}>
              {`${moment?.utc(updatedMatchData?.date).format('DD/MM')} ${getShortMatchStatus(updatedMatchData?.matchStatus, '~', updatedMatchData?.time) ?? ''}` || ''}
            </Typography>
            <Typography className={classes.text} sx={{ mt: '4px' }}>
              {updatedMatchData?.court ? `${updatedMatchData?.court.name}` : ''}
            </Typography>
          </Box>
        )}
      </Fragment>
    );
  };
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {groupsTableData?.length ? (
        groupsTableData?.map((group: Record<string, any>, index: number) => (
          <Box sx={{ width: '100%' }} key={group.id}>
            {groupsTableData?.length > 1 && !group.hide && !group.isDelete && (
              <Box sx={{ margin: '10px 0' }}>
                <Typography
                  sx={{
                    color: '#193660',
                    fontFamily: 'Montserrat',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    padding: '0px 8px',
                  }}
                >
                  {group?.name}
                </Typography>
                {!selectedCat && (
                  <Box
                    sx={{
                      padding: '0px 8px',
                      position: 'relative',
                      display: 'flex',
                      fontSize: '12px',
                    }}
                  >
                    {group?.tournamentCategory?.category?.name}{' '}
                    <Box
                      sx={{
                        fontSize: '10px',
                        ml: 0.2,
                        mt: -0.5,
                      }}
                    >
                      {group?.segment}
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            {!group.hide && !group.isDelete && (
              <Box
                sx={{
                  // minWidth: 700,
                  borderRadius: '6px',
                  border: '1px solid #EDF1F6',
                  background: '#FFF',
                  padding: '0px 8px 8px 8px',
                  overflowX: 'auto',
                  width: '100%',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ height: '36px' }}>
                      <CustomTableCell align="left" className={classes.tablehead}>
                        NR
                      </CustomTableCell>
                      <CustomTableCell align="left" className={classes.tablehead} sx={{ width: '1%', maxWidth: 'max-content' }}>
                        {group?.tournamentCategory?.category?.type == 'single' ? t('Athlete') : group?.tournamentCategory?.category?.type == 'doubles' ? t('Pair') : t('Team')}
                      </CustomTableCell>
                      {group?.groupType !== 'king' &&
                        group?.entries?.map((singleEntry, index) => (
                          <CustomTableCell align="center" className={classes.tablehead} key={singleEntry.id} sx={{ width: '110px' }}>
                            {index + 1}
                          </CustomTableCell>
                        ))}
                      <CustomTableCell align="center" className={classes.tablehead}>
                        {t('Points')}
                      </CustomTableCell>
                      {group?.groupType !== 'king' && (
                        <CustomTableCell align="center" className={classes.tablehead}>
                          SR
                        </CustomTableCell>
                      )}
                      <CustomTableCell align="center" className={classes.tablehead}>
                        PR
                      </CustomTableCell>
                      <CustomTableCell align="center" className={classes.tablehead}>
                        {t('Place')}
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {group?.entries?.map((entry, index) => (
                      <TableRow
                        hover
                        key={entry.id}
                        sx={{
                          border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                          background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                        }}
                      >
                        <CustomTableCell
                          sx={{
                            width: '50px',
                          }}
                        >
                          <Typography className={classes.text}>{index + 1}.</Typography>
                        </CustomTableCell>
                        <CustomTableCell
                          sx={{
                            width: '1%',
                            maxWidth: 'max-content',
                          }}
                          align="left"
                        >
                          <TeamMembers
                            team={entry?.registrationRequest?.team}
                            users={entry?.registrationRequest?.users}
                            showSeed
                            seed={entry?.registrationRequest?.seed}
                            assignedSegment={entry?.assignedSegment}
                            additionalSegment={entry?.additionalSegment}
                            segment={group?.segment}
                            group={group}
                            showTeamMembers={group?.tournamentCategory?.category?.identifyTeamMember}
                          />
                        </CustomTableCell>
                        {group?.groupType !== 'king' &&
                          group?.entries?.map((singleEntry, singleEntryIndex) => (
                            <CustomTableCell
                              align="center"
                              sx={{
                                width: '110px',
                                background: singleEntryIndex === index ? '#EFEFF0' : '#ffff',
                                gap: '4px',
                                flex: '1 0 0',
                                alignSelf: 'stretch',
                                p: '0px 16px',
                              }}
                              className={classes.playerName}
                              key={singleEntry.id}
                            >
                              {singleEntryIndex === index || group?.matches?.length === 0 ? Groups[0].status : MatchesTableCellData(entry?.id, singleEntry?.id, group)}
                            </CustomTableCell>
                          ))}
                        <ScoresCells entryID={entry?.id} isGroup={true} groupType={group?.groupType} entryPlace={entry?.place} />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        ))
      ) : (
        <NoDataFound text="There is no available groups" sx={{ margin: '150px 0' }} />
      )}
    </Box>
  );
};

export default GroupsListView;
