import { Typography, Stack } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomModal from '../';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import useAuth from 'src/hooks/useAuth';
import { useLazyQuery } from '@apollo/client';
import PLATFORM_BY_ID from '../../../../graphql/queries/getplatformById';
import { refetchAllQueries } from '../../../../utils/apolloClient';
import { getPlatformId } from 'src/constants';

interface ServerCrashModalProps {
  open: boolean;
  onClose: () => void;
}

const ServerCrashModal: React.FC<ServerCrashModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const auth = useAuth();

  const platformId = getPlatformId();

  const [getData] = useLazyQuery(PLATFORM_BY_ID, {
    variables: {
      platformId,
    },
  });

  const checkServerError = async () => {
    refetchAllQueries();
    auth?.setServerCrashModalOpen(false);
  };

  useEffect(() => {
    if (!open) return;

    const intervalId = setInterval(checkServerError, 8000);

    return () => clearInterval(intervalId);
  }, [auth, getData, open]);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Cleanup the event listeners on component unmount
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <CustomModal openOvveride={open} onClose={onClose} allowClosing={false} backgroundSx={{ backdropFilter: 'blur(48px)' }}>
      <Stack sx={{ width: '100%', alignItems: 'center', maxWidth: '400px', gap: '20px', alignSelf: 'center', my: '20px' }}>
        <img height="40px" src={auth?.platformData?.lightLogo ?? process.env.REACT_APP_LOGO_ICON} />
        <Typography variant="h6">{t('errorTitle')}</Typography>
        <Typography variant="body1" sx={{ textAlign: 'center' }}>
          {!isOnline ? t('networkErrorMessage') : t('maintenanceMessage')}
        </Typography>
        <CustomButton variant="primary" size="medium" onClick={checkServerError} sx={{ gap: '4px', width: '200px', mt: '4px' }}>
          <RefreshIcon fontSize="small" /> {t('Try again')}
        </CustomButton>
      </Stack>
    </CustomModal>
  );
};

export default ServerCrashModal;
