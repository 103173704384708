import { Box, Typography } from '@material-ui/core';
import { Fragment, useState } from 'react';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import GetFlags from 'src/utils/getFlags';
import { getFideTitle } from 'src/utils/helperFunction';
import { DUPRBox, getColorByRating } from '../dashboard/TournamentPage/participants/Participants';

const UserName = ({ user, fideTitle, shouldDisplayDupr }) => {
  const [openModal, setOpenModal] = useState(false);
  //   const resourceTitle = useResourceTitle();

  return (
    <Fragment>
      <Typography
        variant="body1"
        sx={{
          width: 'max-content',
          color: '#193660',
          fontWeight: 500,
          cursor: 'pointer',
        }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': { color: '#29ABE2' },
              maxWidth: '200px',
            }}
          >
            <GetFlags
              country={user?.user?.citizenship ? user?.user?.citizenship : user?.user?.nation}
              style={{
                width: '1.2em',
                height: '1.2em',
                marginRight: '10px',
              }}
            />
            <CustomAvatar src={user?.user?.avatar} size={26} seed={user?.user?.name + ' ' + user?.user?.surname} />

            <Typography
              variant="font18"
              sx={{
                '&:hover': { color: (theme) => theme.palette.primary.main },
                ml: 1,
                textTransform: 'uppercase',
              }}
            >
              {user?.user?.name ? user?.user?.name : 'Not Added'} {user?.user?.surname ? user?.user?.surname : ''}{' '}
            </Typography>

            {/* {resourceTitle === 'Round' && ( */}
            <Box
              sx={{
                padding: '3px 5px',
                background: getFideTitle(fideTitle)?.color,
                color: '#FFFF',
                borderRadius: '5px',
                marginRight: '10px',
                width: 'max-content',
                marginLeft: '10px',
              }}
            >
              {getFideTitle(fideTitle)?.abbreviation}
            </Box>
            {/* )} */}
            {shouldDisplayDupr && user?.duprRating && <DUPRBox rating={user?.duprRating} color={getColorByRating(user?.user?.duprRating)} />}
          </Box>
        </Box>
      </Typography>
    </Fragment>
  );
};

export default UserName;
