import React from 'react';
import { Typography, Box } from '@material-ui/core';
import CustomAccordion from 'src/components/CustomComponents/CustomAccordian';
import CustomSuccess from './CustomSuccess';
import { useTranslation } from 'react-i18next';

const StepSuccessMessage = ({ tournament }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Box
        sx={{
          padding: { sm: '20px', xs: '15px' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '24px',
        }}
      >
        <img src="/images/done.svg" style={{ cursor: 'pointer', height: '60px', width: '60px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Typography variant="heading2" sx={{ color: '#0A2540', lineHeight: '24px', textAlign: 'center' }}>
            {t('You have successfully registered for the tournament')}
          </Typography>
        </Box>
      </Box>
      <CustomSuccess />
      <CustomAccordion
        summaryText={`${t('If the status of your entry is "Pending",it means the tournament team has received your entry but has not')}`}
        detailsText={`${t('approved it yet.For any questions, please contact the organizer at')}
          ${
            tournament?.organizer?.isOrganization
              ? `${tournament?.organizer?.organizationEmail}  or ${tournament?.organizer?.organizationPhone}.`
              : `${tournament?.organizer?.member?.email}  or ${tournament?.organizer?.member?.phone}.`
          }`}
      />
    </Box>
  );
};

export default StepSuccessMessage;
