import React, { useState, useRef, useEffect, Fragment, useMemo } from 'react';
import { Box, IconButton, Stack, Typography, useMediaQuery } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { Bracket } from 'react-brackets';
import { DOWNLOAD_DRAWS_PDF, DOWNLOAD_DUPR_PDF, DRAWS, TOURNAMENT_DRAWS_CATEGORIES } from 'src/graphql/queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import ThirdPlaceMatch from './ThirdPlaceMatch';
import { DownloadOutlined } from '@material-ui/icons';
import toast from 'react-hot-toast';
import Loading from 'src/components/Loading';
import NoDataFound from '../../NoDataFound';
import DragScrollbar from 'src/components/DragScrollbar';
import gtm from 'src/lib/gtm';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import useDebounce from 'src/utils/debounce';
import DrawsModal from './DrawsModal';
import CustomSeed from './CustomSeeds';
import CustomSection from '../../../layout/CustomSection';
import CustomPopover from '../../../CustomComponents/CustomPopover';
import CustomButton from '../../../CustomComponents/CustomButton';
import CustomSelect from '../../../CustomComponents/CustomSelect';
import { useTournament } from '../TournamentPage';
import { useTranslation } from 'react-i18next';

const roundTitleComponent = (title, currentIndex, setCurrentIndexes, draw, currentIndexes, mobileView) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          padding: '9px 0px',
          justifyContent: mobileView ? 'space-between' : 'center',
          alignItems: 'center',
          gap: '10px',
          // flex: '1 0 0',
          height: '30px',
          borderRadius: '8px',
          background: 'rgba(10, 37, 64, 0.03)',
          margin: { sm: '0 0.7rem 0rem 0.7rem', xs: '0px' },
        }}
      >
        {mobileView && (
          <IconButton
            size="medium"
            sx={{
              height: '20px',
              // background: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              ':hover': {
                color: (theme) => theme.palette.primary.main,
              },
              pointerEvents: currentIndex === 0 ? 'none' : 'auto',
            }}
            onClick={() =>
              setCurrentIndexes({
                ...currentIndexes,
                [draw.id]: currentIndex - 1,
              })
            }
          >
            <img src="/images/newRev.svg" style={{ fontSize: '14px' }} />
          </IconButton>
        )}
        <Typography variant="font15" sx={{ color: '#0A2540', lineHeight: 'normal' }}>
          {title}
        </Typography>
        {mobileView && (
          <IconButton
            size="medium"
            sx={{
              height: '20px',
              // background: (theme) => theme.palette.primary.main,
              color: (theme) => theme.palette.primary.contrastText,
              ':hover': {
                color: (theme) => theme.palette.primary.main,
              },
              pointerEvents: currentIndex === draw?.rounds?.length - 1 ? 'none' : 'auto',
            }}
            onClick={() =>
              setCurrentIndexes({
                ...currentIndexes,
                [draw.id]: currentIndex + 1,
              })
            }
          >
            <img src="/images/newfor.svg" style={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </Box>
    </React.Fragment>
  );
};

const segmentOptions = [
  {
    label: 'Main',
    value: 'MD',
  },
  {
    label: 'Consolation',
    value: 'consolation',
  },
  {
    label: 'Qualification',
    value: 'Q',
  },
];

const Draws = () => {
  const { t } = useTranslation();
  const { id, tournament, setRefetch } = useTournament();
  const navigate = useNavigate();
  const [filtersParams, setFiltersParams] = useSearchParams();
  const [selectedValue, setSelectedValue] = useState<string>(filtersParams.get('category') || '');
  const [selectedQ, setSelectedQ] = useState<string>(filtersParams.get('segment') || 'MD');
  const [currentIndexes, setCurrentIndexes] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedSeed, setSelectedSeed] = useState(null);
  const [isDisplayClub, setIsDisplayClub] = useState(null);
  const [selectedCat, setSelectedCat] = useState();
  const [search] = useState('');
  const [selectedSeedInfo, setSelectedSeedInfo] = useState({ drawId: null, roundIndex: 0, seedIndex: null, direction: false });

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };
  const handleSelectChangeQ = (value) => {
    setSelectedQ(value);
  };
  const mobileView = useMediaQuery('(max-width:993px)');
  const printAllDrawsRef = useRef<HTMLDivElement>(null);
  const { data: DrawsCategories, refetch: refetchDrawsCategories } = useQuery(TOURNAMENT_DRAWS_CATEGORIES, {
    variables: {
      filter: {
        tournament: id,
      },
    },
    onCompleted: (data) => {
      const categories = data?.tournamentDrawCategories;

      setSelectedValue((prev) => categories?.find((item) => item?.id === Number(prev))?.id ?? categories[0]?.id);
      setSelectedCat((prev) => categories?.find((item) => item?.id === Number(selectedValue)) ?? categories[0]);
    },
    pollInterval: 1000 * 30,
  });
  const {
    loading,
    data: drawsData,
    refetch: refetchDrawsData,
  } = useQuery(DRAWS, {
    variables: {
      filter: {
        tournament: id,
        ...(selectedValue ? { tournamentCategory: parseInt(selectedValue) } : {}),
        ...(selectedQ ? { segment: selectedQ } : {}),
      },
    },
  });

  const handleSelectCat = (value) => {
    const selectedCat = DrawsCategories?.tournamentDrawCategories?.find((item) => item?.id == value);

    setSelectedCat(selectedCat);
  };
  const [generatePDF] = useLazyQuery(DOWNLOAD_DRAWS_PDF, {
    variables: {
      id: String(id),
      ...(selectedValue ? { tournamentCategory: String(selectedValue) } : {}),
      ...(selectedQ ? { segment: selectedQ } : {}),
    },
  });

  const handleDrawPdfGenerate = () => {
    toast.promise(generatePDF(), {
      loading: 'Generating PDF...',
      success: (res) => {
        if (res?.data) {
          const link = document.createElement('a');
          link.href = res?.data?.PdfGenerationForDraw;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return <b>PDF being generated...</b>;
      },
      error: <b>Failed to generate PDF</b>,
    });
  };
  const [generateDUPRPDF] = useLazyQuery(DOWNLOAD_DUPR_PDF, {
    variables: {
      id: String(id),
      ...(selectedValue ? { tournamentCategory: selectedValue } : {}),
      ...(selectedQ ? { segment: selectedQ } : {}),
      isManager: false,
    },
  });
  const handleDownloadDUPR = () => {
    toast.promise(generateDUPRPDF(), {
      loading: 'Generating CSV...',
      success: (res) => {
        if (res?.data) {
          const link = document.createElement('a');
          link.href = res?.data?.PdfGenerationForPickleball;
          link.download = 'file.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        return <b>CSV being generated...</b>;
      },
      error: <b>Failed to generate CSV</b>,
    });
  };

  const getBracketClassName = (roundIndex) => {
    if (roundIndex?.roundIndex == 3) {
      return 'even';
    }
    return roundIndex?.roundIndex % 2 === 0 ? 'even' : 'odd';
  };
  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const singleDrawsRefs = useMemo(() => drawsData?.draws?.map(() => React.createRef()), [drawsData?.draws?.join(',')]);
  useDebounce(
    () => {
      const currentParams = Object.fromEntries(filtersParams.entries());
      const newParams = {
        ...currentParams,
        ...(selectedValue ? { category: selectedValue } : {}),
        ...(selectedQ ? { segment: selectedQ } : {}),
      };

      // Remove parameters with empty values
      if (!selectedValue) {
        delete newParams.category;
      }
      if (!selectedQ) {
        delete newParams.segment;
      }

      setFiltersParams(newParams);
    },
    [selectedValue, selectedQ],
    500,
  );

  useEffect(() => {
    const refetchData = () => {
      void refetchDrawsData();
      void refetchDrawsCategories();
      toast.success('Draws data has been successfully refreshed.');
    };

    setRefetch(refetchData);

    return () => setRefetch(undefined);
  }, []);

  const filtersEl = (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', gap: '8px', alignItems: 'center' }}>
      <CustomSelect
        placeholder="Category"
        onClear={() => {
          handleSelectChange('');
          handleSelectCat('');
        }}
        onChange={(value) => {
          handleSelectChange(value);
          handleSelectCat(value);
        }}
        selectedValue={selectedValue}
        buttonProps={{ sx: { flex: { xs: 1, sm: 'none' } } }}
        options={DrawsCategories?.tournamentDrawCategories?.map((type) => ({ title: type.category?.name, value: type?.id }))}
      />
      <CustomSelect
        placeholder="Stage"
        onClear={() => handleSelectChangeQ('')}
        selectedValue={selectedQ}
        buttonProps={{ sx: { flex: { xs: 1, sm: 'none' } } }}
        onChange={(value) => handleSelectChangeQ(value)}
        options={segmentOptions.map((category) => ({ title: t(category?.label), value: category?.value }))}
      />
      {tournament?.league?.league?.sports?.title?.toLowerCase() === 'pickleball' ? (
        <CustomPopover
          triggerEl={
            <CustomButton size="small" variant="outline" square>
              <DownloadOutlined sx={{ fontSize: '18px' }} />
            </CustomButton>
          }
        >
          <Stack gap="8px">
            <CustomButton variant="primary" sx={{ width: '100%' }} size="small" onClick={() => handleDrawPdfGenerate()}>
              Download PDF
            </CustomButton>
            {/* <CustomButton variant="primary" sx={{ width: '100%' }} size="medium" onClick={() => handleDownloadDUPR()}>
              Export matches for DUPR
            </CustomButton> */}
          </Stack>
        </CustomPopover>
      ) : (
        <CustomButton square size="small" variant="outline" onClick={() => handleDrawPdfGenerate()}>
          <DownloadOutlined sx={{ fontSize: '18px' }} />
        </CustomButton>
      )}
    </Box>
  );

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Fragment>
      <Helmet>
        <title>{tournament?.title ?? ''} | Draws</title>
      </Helmet>
      <CustomSection title={t('Draws')} titleRightSideElement={!isMobile && filtersEl} underTitleElement={isMobile && filtersEl}>
        <Box
          sx={{
            overflowX: 'auto',
            width: '100%',
            borderRadius: '8px',
          }}
          ref={printAllDrawsRef}
        >
          {loading ? (
            <Loading height="50vh" />
          ) : drawsData?.draws?.length > 0 ? (
            // dummyDraws?.length > 0 ? (
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: '8px',
                padding: '10px',
              }}
            >
              {drawsData?.draws?.map((draw, index) => {
                const currentIndex = currentIndexes[draw?.id] || 0;

                // dummyDraws?.map((draw, index) => (
                return (
                  !draw.hide && (
                    <Box sx={{ mb: '30px' }} key={draw?.id} ref={singleDrawsRefs[index]}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '15px',
                          }}
                        >
                          <Typography
                            sx={{
                              width: 'max-content',
                              color: '#1C3962',
                              pb: '4px',
                              fontFamily: 'Montserrat',
                              fontWeight: '600',
                              borderBottom: '2px solid #edeaea',
                            }}
                            component="h2"
                          >
                            {draw.title + ' '}
                            <sup>
                              {draw?.segment}({draw?.tournamentCategory?.category?.name})
                            </sup>
                          </Typography>
                        </Box>
                      </Box>
                      <DragScrollbar
                        style={{
                          width: '100%',
                          overflow: 'scroll',
                        }}
                        className="my-scroller"
                      >
                        <Box sx={{ width: { md: 'min-content', xs: 'initial' }, '@media print': { width: '100%' } }}>
                          <Bracket
                            bracketClassName={getBracketClassName(selectedSeedInfo)}
                            swipeableProps={{
                              index: currentIndex,
                              style: { slide: { background: 'red', height: 'auto' } },
                              animateHeight: true,
                            }}
                            rounds={draw.rounds}
                            renderSeedComponent={(props) => (
                              <CustomSeed
                                {...props}
                                drawCategory={draw?.tournamentCategory}
                                drawId={draw?.id}
                                draw={draw}
                                drawSegment={draw?.segment}
                                navigate={navigate}
                                setOpenModal={setOpenModal}
                                setSelectedSeed={setSelectedSeed}
                                setIsDisplayClub={setIsDisplayClub}
                                search={search}
                                breakpoint={0}
                                currentIndexes={currentIndexes}
                                currentIndex={currentIndex}
                                setCurrentIndexes={setCurrentIndexes}
                                mobileView={mobileView}
                                index={index}
                                setSelectedSeedInfo={setSelectedSeedInfo}
                                selectedSeedInfo={selectedSeedInfo}
                                isTeam={draw?.tournamentCategory?.category?.type === 'team'}
                              />
                            )}
                            roundTitleComponent={(round) => roundTitleComponent(round, currentIndex, setCurrentIndexes, draw, currentIndexes, mobileView)}
                          />
                          {draw && draw?.thirdPlaceRound && (mobileView ? currentIndex === draw?.rounds?.length - 1 : true) && Object.keys(draw?.thirdPlaceRound).length > 0 && (
                            <ThirdPlaceMatch
                              thirdPlaceRound={draw?.thirdPlaceRound}
                              key={draw?.id}
                              draw={draw}
                              isTeam={draw?.tournamentCategory?.category?.type === 'team'}
                              drawCategory={draw?.tournamentCategory}
                              isSmallScreen={isMobile}
                            />
                          )}
                        </Box>
                      </DragScrollbar>
                    </Box>
                  )
                );
              })}
            </Box>
          ) : (
            <NoDataFound text="There is no available draws" sx={{ margin: '200px 0' }} />
          )}
        </Box>
        <DrawsModal open={openModal} setOpen={setOpenModal} seed={selectedSeed} selectedCat={selectedCat} isDisplayClub={isDisplayClub} />
      </CustomSection>
    </Fragment>
  );
};

export default Draws;
