import React from 'react';
import PlayerInfo from './PlayerInfo';
import MatchesScores from '../MatchesScores';
import { parseScores } from 'src/constants';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import getFormattedScore from '../formattedScore';
import { USER_ALL_MATCHES } from 'src/graphql/queries';
import { useQuery } from '@apollo/client';
import useAuth from 'src/hooks/useAuth';
import { useParams } from 'react-router';
import moment from 'moment';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import NoDataFound from '../NoDataFound';
import Loading from 'src/components/Loading';
import { useTournament } from './TournamentPage';

const NoScore = ({ scoreType }) => (
  <Box sx={{ display: 'flex', padding: '5px' }}>
    <Typography
      sx={{
        display: 'flex',
        color: '#19366066',
        fontFamily: 'Inter',
        fontWeight: '500',
        fontSize: '10px',
        textTransform: 'uppercase',
      }}
    >
      {scoreType ?? ''}
    </Typography>
  </Box>
);

const AthleteSpaceMatches = () => {
  const { tournament } = useTournament();
  const auth = useAuth();
  const { data, loading } = useQuery(USER_ALL_MATCHES, {
    variables: {
      user: parseInt(auth?.user?.id),
      filter: {
        id: tournament.id,
      },
    },
  });
  return (
    <Box sx={{ flex: 1, overflow: 'auto' }}>
      {loading ? (
        <Loading height="35vh" />
      ) : data?.userTournamentMatches?.userTournamentMatches?.length > 0 ? (
        data?.userTournamentMatches?.userTournamentMatches?.map((record) =>
          record?.matches?.length > 0 ? (
            record?.matches?.map((item) => (
              <Box sx={{ width: '100%', padding: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '20px', flexDirection: { sm: 'row', xs: 'column' } }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '50%', justifyContent: 'space-between' }}>
                    {' '}
                    {item?.court?.name && (
                      <CustomChip size="small" variant="secondary" sx={{ borderRadius: '4px', background: '#FAFAFA', height: '25px' }}>
                        <Typography variant="font21" sx={{ color: '#0A2540', lineHeight: '20px' }}>
                          {item?.court?.name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary"></Typography>
                      </CustomChip>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '14.52px' }}>
                        {moment(item?.date).format('DD/MM')}
                      </Typography>
                      <Typography variant="caption" sx={{ color: '#0A254059', lineHeight: '14.52px' }}>
                        {item?.time}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    {' '}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {' '}
                        {item?.entry1?.users?.map((player, index) => (
                          <PlayerInfo key={index} player={player?.user} />
                        ))}
                      </Box>

                      <Box>
                        <Typography variant="subtitle1" align="center">
                          vs
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {' '}
                        {item?.entry2?.users?.map((player, index) => (
                          <PlayerInfo key={index} player={player?.user} />
                        ))}
                      </Box>
                    </Box>
                    <Box sx={{ border: '1px solid #EDF1F6', height: 'max-content', borderRadius: '6px' }}>
                      {item?.score ? (
                        typeof item?.score === 'string' ? (
                          <>
                            {item?.winner?.id === item?.entry1?.id ? (
                              <MatchesScores scores1={parseScores(item?.score)?.firstArray} scores2={parseScores(item?.score)?.secondArray} winner={true} />
                            ) : (
                              <MatchesScores scores1={parseScores(item?.score)?.secondArray} scores2={parseScores(item?.score)?.firstArray} winner={false} />
                            )}
                            {item?.winner?.id === item?.entry2?.id ? (
                              <MatchesScores scores1={parseScores(item?.score)?.firstArray} scores2={parseScores(item?.score)?.secondArray} winner={true} />
                            ) : (
                              <MatchesScores scores1={parseScores(item?.score)?.secondArray} scores2={parseScores(item?.score)?.firstArray} winner={false} />
                            )}
                          </>
                        ) : (
                          <>{getFormattedScore(item?.score, item, item?.scoreType)}</>
                        )
                      ) : (
                        <NoScore scoreType={item?.scoreType} />
                      )}
                    </Box>
                  </Box>
                </Box>

                <Divider sx={{ marginY: 2 }} />
              </Box>
            ))
          ) : (
            <NoDataFound text="No Match found" sx={{ height: '35vh' }} />
          ),
        )
      ) : (
        <NoDataFound text="No Match found" sx={{ height: '35vh' }} />
      )}
    </Box>
  );
};

export default AthleteSpaceMatches;
