import { gql } from "@apollo/client";

const CREATE_ORGANIZATION = gql`
  mutation createFederation(
    $federationEmail: String!
    $role: String!
    $organizationName: String!
    $organizationAbbreviation: String!
    $logo: Upload
    $about: String
    $contactEmail: String!
    $contactPhone: String
    $website: String
    $instagram: String
    $tournatedPlatformId: [Int!]
  ) {
    createFederation(
      createFederationInput: {
        federationEmail: $federationEmail
        role: $role
        organizationName: $organizationName
        organizationAbbreviation: $organizationAbbreviation
        logo: $logo
        about: $about
        contactEmail: $contactEmail
        contactPhone: $contactPhone
        website: $website
        instagram: $instagram
        tournatedPlatformId: $tournatedPlatformId
      }
    ) {
      federation {
        id
      }
      accessToken
    }
  }
`;

export default CREATE_ORGANIZATION;
