import { useRef } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme, useMediaQuery, Stack } from '@material-ui/core';
import Cookies from 'js-cookie';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import CustomButton from 'src/components/CustomComponents/CustomButton';
import { ManageAccounts } from '@material-ui/icons';
import CustomPopover from 'src/components/CustomComponents/CustomPopover';


const MenuItems = [
  {
    id: 0,
    title: 'Tournated Manager',
    desc: 'In-Platform management',
    path: '/pricing?plan=manager',
  },
  {
    id: 1,
    title: 'Tournated Custom',
    desc: 'Launch your own platform',
    path: '/pricing?plan=custom',
  },
];

const EventMenu: FC<any> = ({ isManager }) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const auth = useAuth();
  const navigate = useNavigate();

  const token = Cookies?.get('token');
  const { t } = useTranslation();

  const handleMenuItemClick = (item) => {
    navigate(item.path);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    !isMobile && (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {auth?.user && isManager && (
          <CustomButton variant="secondary" ref={anchorRef} size="small" sx={{ gap: '4px' }} onClick={() => window.open(`${process.env.REACT_APP_FE_URL}/dashboard/all-tournaments?token=${token}`)}>
            <ManageAccounts sx={{ fontSize: '14px' }} /> {t('Manager')}
          </CustomButton>
        )}
        {/* {auth?.platformData?.isEnablePrice && ( */}
        {/* {auth?.user && ( */}
        {auth?.platformData?.isEnablePrice && (
          <CustomPopover
            triggerEl={
              <CustomButton variant="secondary" ref={anchorRef} size="small" sx={{ gap: '4px' }}>
                <MonetizationOnOutlinedIcon sx={{ fontSize: '14px' }} />
                {t('pricing')}
              </CustomButton>
            }
          >
            {(close) => (
              <Stack gap="4px">
                {MenuItems.map((item) => (
                  <CustomButton
                    variant="outline"
                    size="auto"
                    onClick={() => {
                      handleMenuItemClick(item);
                      close();
                    }}
                    key={item.id}
                    sx={{ flexDirection: 'column', alignItems: 'start', paddingInline: '8px', paddingBlock: '4px' }}
                  >
                    <Typography
                      sx={{
                        color: '#0A2540',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '14px',
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#86909F',
                        fontFamily: 'Inter',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '16px',
                      }}
                    >
                      {item.desc}
                    </Typography>
                  </CustomButton>
                ))}
              </Stack>
            )}
          </CustomPopover>
        )}
        {/* )} */}
        {/* // )} */}
      </Box>
    )
  );
};

export default EventMenu;
