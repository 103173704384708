import React, { useEffect, useState } from 'react';
import { List, Box, Typography, Divider, Checkbox } from '@material-ui/core';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import { getCurrencySymbol, parseToJSON } from 'src/utils/helperFunction';
import { calculateFee } from 'src/utils/formatFee';
import CustomAvatar from 'src/components/CustomComponents/CustomAvatar';
import SelectedPlayerDisplay from './SelectedPlayer';

const StepSummary = ({ tournamentCategories, priceUnit, isPayForMyselfEnabled, calculations }) => {
  const { registrationData, updateRegistrationData } = useTournamentRegistrationContext();
  const [totalOnsiteFee, setTotalOnsiteFee] = useState(0);
  const [totalOnlineFee, setTotalOnlineFee] = useState(0);
  const [showPayForMyself, setShowPayForMyself] = useState(false);
  const displayedQuestions = new Set();

  const calculateAdditionalFees = () => {
    registrationData?.selectedCategories?.forEach((categoryEntry) => {
      const calculateFeeForPlayer = (playerQuestions) =>
        playerQuestions?.reduce((acc, question) => {
          if (question.fieldType === 'Select' && question.answer) {
            const selectedOption = parseToJSON(question.fieldOptions).find((option) => option.price === question.answer);
            return acc + (parseFloat(selectedOption?.price) || 0);
          }
          return acc;
        }, 0) || 0;

      const updatedAdditionalFees = {
        player1: calculateFeeForPlayer(categoryEntry?.player1additionalQuestions),
        player2: calculateFeeForPlayer(categoryEntry?.player2additionalQuestions),
      };

      categoryEntry.additionalFees = updatedAdditionalFees;
    });
    updateRegistrationData({ selectedCategories: registrationData.selectedCategories });
  };

  const calculateTotals = () => {
    let totalOnsite = 0;
    let totalOnline = 0;
    const anyDoublesPerAthlete = registrationData?.selectedCategories?.some(
      (categoryEntry) => categoryEntry?.category?.category?.type === 'doubles' && categoryEntry?.category?.onlinePayments?.toLowerCase() === 'per athlete',
    );

    registrationData?.selectedCategories?.forEach((categoryEntry, index) => {
      const { category, additionalFees } = categoryEntry;
      const additionalFee = (additionalFees?.player1 || 0) + (additionalFees?.player2 || 0);
      const isDoubleAmount = categoryEntry?.category?.category?.type === 'doubles' && categoryEntry?.category?.onlinePayments?.toLowerCase() === 'per athlete';
      const totalFee = isDoubleAmount ? category?.fee * 2 + additionalFee : category?.fee + additionalFee;
      const totalOnsiteFee = isDoubleAmount ? category?.onsiteFee * 2 + additionalFee : category?.onsiteFee + additionalFee;

      totalOnline += totalFee;
      totalOnsite += totalOnsiteFee;
    });
    setShowPayForMyself(isPayForMyselfEnabled ? anyDoublesPerAthlete : false);
    setTotalOnlineFee(totalOnline);
    setTotalOnsiteFee(totalOnsite);
  };
  const allFeesZeroOrNull = registrationData?.selectedCategories?.every(
    (categoryEntry) => (categoryEntry?.category?.fee === 0 || categoryEntry?.category?.fee == null) && (categoryEntry?.category?.onsiteFee === 0 || categoryEntry?.category?.onsiteFee == null),
  );
  useEffect(() => {
    calculateAdditionalFees();
    calculateTotals();
  }, [registrationData?.selectedCategories]);

  const renderAnswer = (answer, fieldType, fieldOptions) => {
    if (fieldType === 'File') {
      return answer instanceof File ? answer.name : 'No file selected';
    } else if (fieldType === 'Select') {
      const selectedOption = fieldOptions?.find((option) => option.id === parseInt(answer));
      return selectedOption?.question;
    }
    return Array.isArray(answer) ? answer.join(', ') : answer;
  };
  const renderPlayerSection = (player, additionalQuestions, isPlayer2, isDoubleAmount, category) => (
    <Box key={player?.id}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <CustomAvatar src={player?.avatar} size={20} seed={player?.name + ' ' + player?.surname} />
          <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '16.98px', textTransform: 'capitalize' }}>
            {`${player?.name} ${player?.surname}`}
          </Typography>
        </Box>
        <Typography sx={{ color: '#0A2540', lineHeight: '18px', whiteSpace: 'nowrap' }} variant="font15">
          {isPlayer2 && !isDoubleAmount ? '' : calculateFee(category)}
        </Typography>{' '}
      </Box>
      <List disablePadding>
        {additionalQuestions
          ?.filter((question) => question?.answer)
          ?.map((question) => {
            const questionKey = `${question.questionId}-${question.user}`;
            if (displayedQuestions.has(questionKey) && question.askOnlyOnce) return null;
            displayedQuestions.add(questionKey);

            const selectedOption = question?.fieldOptions?.find((option) => option?.id === parseInt(question?.answer));
            const additionalPrice = selectedOption ? parseFloat(selectedOption?.price) : 0;

            return (
              <Box key={question.questionId} sx={{ paddingLeft: '0px', marginBottom: '8px' }}>
                <Typography sx={{ color: '#86909F', lineHeight: '18px' }} variant="font13">
                  {question?.question}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Typography sx={{ color: '#0A2540', lineHeight: '18px' }} variant="font21">
                    {renderAnswer(question?.answer, question?.fieldType, question?.fieldOptions)}
                  </Typography>
                  {additionalPrice !== 0 && (
                    <Typography sx={{ color: '#0A25408C', lineHeight: '18px' }} variant="font13">
                      {getCurrencySymbol(priceUnit)}
                      {additionalPrice?.toFixed(2)}
                    </Typography>
                  )}
                </Box>
              </Box>
            );
          })}
      </List>
    </Box>
  );
  const renderTeamSection = (team, category, members) => (
    <Box key={team?.id}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px', mb: 1 }}>
            <img src={team?.image} style={{ width: '25px', height: '25px' }} />
            <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '16.98px', textTransform: 'capitalize' }}>
              {`${team?.title}`}
            </Typography>
          </Box>
          {category?.category?.identifyTeamMember && (
            <Box>
              {members?.map((item) => (
                <Box sx={{ mb: 1 }}>
                  <SelectedPlayerDisplay player={item} onRemove={() => {}} isFirstPlayer={false} flow={registrationData?.flow} isSummary={true} />
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Typography sx={{ color: '#0A2540', lineHeight: '18px', whiteSpace: 'nowrap' }} variant="font15">
          {calculateFee(category)}
        </Typography>{' '}
      </Box>
    </Box>
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        maxHeight: '650px',
        overflowY: 'auto',
        paddingRight: '10px',
      }}
    >
      <Box>
        {showPayForMyself && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              size="small"
              color="primary"
              checked={registrationData?.payForMyself || false}
              onChange={(e) => {
                updateRegistrationData({ payForMyself: e.target.checked });
              }}
            />
            <Typography variant="font21" sx={{ color: '#0A2540', lineHeight: '18px' }}>
              Pay only for myself
            </Typography>
          </Box>
        )}
        {registrationData?.selectedCategories?.map((categoryEntry, index) => {
          const { category, partners, additionalFees, player1additionalQuestions, player2additionalQuestions, team, members } = categoryEntry;
          const isDoubleAmount = category?.category?.type === 'doubles' && category?.onlinePayments?.toLowerCase() === 'per athlete';
          const isDoubleAmountTeam = category?.category?.type === 'team' && category?.onlinePayments?.toLowerCase() === 'per team';

          const categoryPayment = calculations?.calculatePayments?.calculatedPayments?.find((payment) => payment?.categoryId == category?.category?.id);
          return (
            <Box key={category?.id} sx={{ marginBottom: '16px', padding: '16px', border: '1px solid #EDF1F6', borderRadius: '8px' }}>
              <Typography variant="font18" sx={{ color: '#0A2540', lineHeight: '18px' }}>
                {category?.category?.name}
              </Typography>
              <Divider sx={{ marginTop: '10px', marginBottom: '15px', color: '#EDF1F6' }} />
              {partners?.player1 && <>{renderPlayerSection(partners?.player1, player1additionalQuestions, false, isDoubleAmount, category)}</>}
              {partners?.player2 && (
                <>
                  <Divider sx={{ margin: '15px 0' }} />
                  {renderPlayerSection(partners?.player2, player2additionalQuestions, true, isDoubleAmount, category)}
                </>
              )}
              {team?.id && renderTeamSection(team, category, members)}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default StepSummary;
