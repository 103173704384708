import { useState, FC, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, makeStyles, withStyles, useMediaQuery, Stack } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router';
import GetFlag from 'src/utils/getFlags';
import { Info } from '@material-ui/icons';
import ApplyModal from './ApplyModal';
import InfoModal from './InfoModal';
import useAuth from 'src/hooks/useAuth';
import WarningPopup from 'src/components/shared/WarningPopup';
import { useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { DECLINE_PARTNER_SEARCH, REMOVE_PARTNER_SEARCH } from 'src/graphql/mutations';
import CustomButton from '../../../CustomComponents/CustomButton';
import chroma from 'chroma-js';
import { Theme } from '@material-ui/core/styles';
import CustomAvatar from '../../../CustomComponents/CustomAvatar';
import { useTranslation } from 'react-i18next';
import { DUPRBox, getColorByRating } from '../participants/Participants';
import { ReactSVG } from 'react-svg';
import { useTournamentRegistrationContext } from 'src/contexts/TournamentRegistrationContext';
import { parseToJSON } from 'src/utils/helperFunction';
import { GETUSERBYID, USER_TOURNAMENT_CATEGORIES } from 'src/graphql/queries';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'rgba(25, 54, 96, 0.70)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  name: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
  country: {
    color: '#1B3861',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineheight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  selectInput: {
    '& .MuiInputBase-root': {
      height: '36px!important',
    },
  },
  textfield: {
    // height: "41px",
    flexShrink: 0,
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      //   height: "40px",
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  labelfield: {
    color: '#1B3861',
    fontSize: '12px',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
  },
  tablehead: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  subText: {
    color: '#193660',
    // textAlign: "center",
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
}));

const CustomTableRow = withStyles({
  root: {
    borderBottom: 'none',
    padding: 0,
  },
})(TableCell);

const CustomTableHead = withStyles({
  root: {
    borderBottom: 'none',
    padding: '0px 15px 8px 15px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#86909F',
  },
})(TableCell);

const PartnerSearchListView: FC<any> = ({ PartnerSearch, categories, tournament, selectedValue }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [applyModal, setApplyModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const { user, setLoginModalOpen, setTournamentRegistrationModalOpen } = useAuth();
  const { registrationData, updateRegistrationData, setActiveStep, activeStep } = useTournamentRegistrationContext();
  const [params] = useSearchParams();
  const [warningModal, setWarningModal] = useState(false);
  const [acceptWarning, setAcceptWarning] = useState(false);
  const [firstSelectedMethod, setFirstSelectedMethod] = useState(null);
  const isRegistrationClosed = tournament?.isRegistrationClosed || tournament?.closeRegistration || tournament?.tournamentCategory?.every((category) => category?.status?.toLowerCase() === 'closed');
  const shouldDisplayDupr = tournament?.isDuprRatingEnabled;

  const [getUserByEmail, { data }] = useLazyQuery(GETUSERBYID, {
    fetchPolicy: 'network-only',
  });

  const handleApplyModal = (item) => {
    setSelectedItem(item);
    setApplyModal(true);
  };

  const handleInfoModal = (item) => {
    setSelectedItem(item);
  };

  const declinePartner = params.get('declinePartner');
  const partnerSearch = params.get('partnerSearch');
  const paramCategory = params.get('category');
  const user1 = params.get('user1');
  const user2 = params.get('user2');

  useEffect(() => {
    if (declinePartner) {
      setWarningModal(true);
    }
    if (partnerSearch && user2) {
      Promise.resolve(
        getUserByEmail({
          variables: {
            email: user2,
          },
        }),
      );
      setAcceptWarning(true);
    }
    return () => {
      setWarningModal(false);
      setAcceptWarning(false);
    };
  }, [declinePartner, partnerSearch, user2]);

  const [declineReq] = useMutation(DECLINE_PARTNER_SEARCH, {
    refetchQueries: ['partnerSearches', 'userPartnerSearches'],
  });

  const handleDecline = () => {
    setWarningModal(false);
    toast.promise(
      declineReq({
        variables: {
          id: parseInt(declinePartner),
          partner: null,
        },
      }),
      {
        loading: 'Declining...',
        success: () => {
          navigate(`/tournament/${tournament?.id}?tab=search`);
          return `Declined ${user2} as your partner`;
        },
        error: 'Error declining',
      },
    );
  };

  const isCategoryDisabled = (category) => {
    const categoryMethod = category?.method;
    const categoryType = category?.category?.type;
    const categoryStatus = category?.category?.status;

    const isTeamSelected = registrationData?.selectedCategories?.some((entry) => entry?.category?.category?.type === 'team');
    const isNonTeamSelected = registrationData?.selectedCategories?.some((entry) => entry?.category?.category?.type !== 'team');

    if (isTeamSelected && categoryType !== 'team') {
      return { isDisabled: true, message: 'Please select team categories only.' };
    }

    if (isNonTeamSelected && categoryType === 'team') {
      return { isDisabled: true, message: 'Please select categories other than team categories.' };
    }
    const checks = [
      { condition: category?.isRegistered, message: '' },
      { condition: category?.type?.status === 'Closed', message: '' },
      { condition: categoryStatus === 'inActive', message: '' },
      { condition: categoryStatus === 'null', message: 'This category is unavailable.' },
      {
        condition: firstSelectedMethod && categoryMethod !== firstSelectedMethod,
        message: 'This category does not match the selected method.',
      },
    ];

    for (const { condition, message } of checks) {
      if (condition) {
        return { isDisabled: true, message };
      }
    }

    // If no conditions match, the category is enabled
    return { isDisabled: false, message: '' };
  };

  const handleCategorySelect = (category) => {
    let updatedCategories = [...registrationData?.selectedCategories];
    const isSelected = updatedCategories?.find((entry) => entry?.category?.id === category?.id);

    if (isSelected) {
      updatedCategories = updatedCategories?.filter((entry) => entry?.category?.id !== category?.id);
      if (updatedCategories?.length === 0) {
        setFirstSelectedMethod(null);
        updateRegistrationData({ paymentMethod: null });
      }
    } else {
      const player1AdditionalQuestions =
        category?.additionalQuestions
          ?.filter((question) => !question?.isHide)
          ?.map((question) => ({
            questionId: question?.id,
            question: question?.question,
            fieldOptions: question?.fieldOptions ? parseToJSON(question?.fieldOptions)?.map((option, i) => ({ ...option, id: option?.id || i + 1 })) : [],
            fieldType: question?.fieldType,
            answer: question?.fieldOptions?.[0]?.price || '',
            askEachAthlete: question?.askEachAthlete,
            isMandatory: question?.isMandatory,
            askOnlyOnce: question?.askOnlyOnce,
            file: null,
            user: user?.id,
          })) ?? [];

      let player2AdditionalQuestions = [];

      if (category?.category?.type === 'doubles') {
        player2AdditionalQuestions =
          category?.additionalQuestions
            ?.filter((question) => question?.askEachAthlete && !question?.isHide)
            ?.map((question) => ({
              questionId: question?.id,
              question: question?.question,
              fieldOptions: question?.fieldOptions ? parseToJSON(question?.fieldOptions)?.map((option, i) => ({ ...option, id: option?.id || i + 1 })) : [],
              fieldType: question?.fieldType,
              answer: question?.fieldOptions?.[0]?.price || '',
              askEachAthlete: question?.askEachAthlete,
              isMandatory: question?.isMandatory,
              askOnlyOnce: question?.askOnlyOnce,
              file: null,
            })) ?? [];
      }

      const newCategoryEntry = {
        category: {
          ...category,
        },
        partners: {
          player1: user,
          player2: data?.user,
        },
        team: {},
        members: [],
        player1additionalQuestions: player1AdditionalQuestions,
        player2additionalQuestions: player2AdditionalQuestions,
        additionalFees: { player1: 0, player2: 0 },
        totalAdditionalFees: 0,
        currency: category?.currency || '',
      };

      updatedCategories.push(newCategoryEntry);
      if (updatedCategories?.length === 1) {
        setFirstSelectedMethod(category?.method);
        updateRegistrationData({ paymentMethod: category?.method });
      }
    }

    updateRegistrationData({ selectedCategories: updatedCategories });
  };

  const handleConfirm = () => {
    setAcceptWarning(false);
    const category = categories?.find((category) => category?.category?.id === parseInt(paramCategory))?.category;
    const disabled = isCategoryDisabled(category);
    if (category) {
      if (!disabled?.isDisabled) {
        setTournamentRegistrationModalOpen(true);
        handleCategorySelect(category);
        category?.additionalQuestions?.length > 0 ? setActiveStep(2) : setActiveStep(3);
        navigate(`/tournament/${tournament?.id}?tab=search`);
      } else {
        toast.error(category?.message || disabled?.message || 'Category unavailable');
      }
      handleCategorySelect(category);
    } else {
      toast.error('Category not found');
    }
  };

  const [removePartnerSearch] = useMutation(REMOVE_PARTNER_SEARCH, {
    refetchQueries: ['partnerSearches', 'userPartnerSearches'],
  });

  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async (entryData, handleClose?: () => void) => {
    try {
      const variables = {
        id: entryData?.id,
      };
      await removePartnerSearch({ variables });
      handleClose?.();
      toast.success(`Partner search record removed successfully`);
    } catch (err) {
      toast.error(err?.message);
      setIsDeleting(false);
    }
  };

  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const { t } = useTranslation();

  return (
    <>
      {isTablet ? (
        <Stack sx={{ width: '100%' }}>
          {PartnerSearch?.length > 0 &&
            PartnerSearch?.map((entry, index) => (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  gap: '8px',
                  ':not(:last-child)': { borderBottom: '1px solid rgba(0, 0, 0, 0.10)', paddingBottom: '12px' },
                  ':not(:first-child)': { paddingTop: '12px' },
                }}
              >
                <Typography variant="font15" sx={{ textTransform: 'uppercase', lineHeight: '26px' }}>
                  {index + 1}.
                </Typography>
                <Stack sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                      <GetFlag country={entry?.user?.citizenship ? entry?.user?.citizenship : entry?.user?.nation} style={{ width: '15px', height: '12px', marginTop: '3px', borderRadius: '3px' }} />

                      <CustomAvatar onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)} src={entry?.user?.avatar} size={24} seed={entry?.user?.name + ' ' + entry.user?.surname} />
                      <CustomButton size="auto" variant="text" onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)} sx={{ gap: '4px', alignItems: 'center' }}>
                        <Typography color="#0A2540" variant="font18">
                          {`${entry?.user?.name?.charAt(0).toUpperCase()}. ${entry?.user?.surname}`}
                        </Typography>
                        <ReactSVG src={`/images/${entry?.user?.gender?.toLowerCase()}.svg`} style={{ width: '16px', height: '16px', color: '#86909F' }} wrapper="span" />
                        {shouldDisplayDupr && entry?.user?.duprRating && <DUPRBox rating={entry?.user?.duprRating} color={getColorByRating(entry?.user?.duprRating)} />}
                      </CustomButton>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 1,
                        maxWidth: 'max-content',
                      }}
                    >
                      <Box sx={{ width: '6px', height: '6px', borderRadius: '4px', backgroundColor: entry?.status === 'found' ? '#14a541' : '#ff5733' }}></Box>
                      <Typography className={classes.country} sx={{ textTransform: 'capitalize' }}>
                        {entry?.status === 'active' ? t('Searching...') : entry?.status}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      paddingBlock: '14px',
                      gap: '20px',
                    }}
                  >
                    <Stack>
                      <Typography variant="font21" color="#0d6f30" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                        {entry?.categoryId?.category?.name}
                      </Typography>
                      <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                        {t('Category')}
                      </Typography>
                    </Stack>
                    {entry?.categoryId?.category?.isDisplayRank && (
                      <Stack>
                        <Typography variant="font21" sx={{ textTransform: 'uppercase', lineHeight: '100%' }}>
                          #{entry?.rank ?? 0}
                        </Typography>
                        <Typography variant="font21" color="#00000059" sx={{ lineHeight: '100%', mt: '3px' }}>
                          {t('Rank')}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <InfoModal entryData={selectedItem} onClick={() => handleInfoModal(entry)} setApplyModal={setApplyModal} isDeleting={isDeleting} handleDelete={handleDelete}>
                      <CustomButton
                        disabled={isRegistrationClosed}
                        size="medium"
                        shape="chip"
                        variant="primary"
                        color={'#0A25400D'}
                        sx={{ paddingInline: '12px', gap: '4px', 'svg > *': { fill: '#0A254059' }, color: '#0A254059' }}
                      >
                        <Info /> {t('Info')}
                      </CustomButton>
                    </InfoModal>
                    {entry?.status === 'found' ? (
                      <CustomButton disabled size="medium" variant="outline" sx={{ minWidth: '100px', flex: 1, maxWidth: '150px' }}>
                        Partner found
                      </CustomButton>
                    ) : user?.id ? (
                      entry?.user?.id === user?.id ? (
                        <CustomButton
                          size="medium"
                          variant="primary"
                          color="#C41E3A"
                          sx={{ width: '100px', flex: 1, maxWidth: '150px' }}
                          onClick={() => {
                            handleDelete(entry);
                          }}
                        >
                          {t('Delete')}
                        </CustomButton>
                      ) : (
                        <CustomButton
                          size="medium"
                          variant="primary"
                          disabled={isRegistrationClosed}
                          sx={{ width: '100px', flex: 1, maxWidth: '150px' }}
                          onClick={() => {
                            handleApplyModal(entry);
                          }}
                        >
                          {t('Apply')}
                        </CustomButton>
                      )
                    ) : (
                      <CustomButton
                        size="medium"
                        variant="outline"
                        sx={{ minWidth: '100px', flex: 1, maxWidth: 'max-content', whiteSpace: 'pre-wrap' }}
                        onClick={() => {
                          setLoginModalOpen(true);
                        }}
                        disabled={entry?.status === 'found' || entry?.user?.id === user?.id}
                      >
                        {t('Log in to apply')}
                      </CustomButton>
                    )}
                  </Box>
                </Stack>
              </Box>
            ))}
        </Stack>
      ) : (
        <Box sx={{ minWidth: 800, width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableHead sx={{ width: '1%' }}>#</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}>{t('Player')}</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}>{t('Category')}</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}>{t('Status')}</CustomTableHead>
                <CustomTableHead sx={{ width: '10%' }}></CustomTableHead>
              </TableRow>
            </TableHead>
            <TableBody>
              {PartnerSearch?.length > 0 &&
                PartnerSearch?.map((entry, index) => (
                  <TableRow
                    hover
                    key={entry?.id}
                    sx={{
                      'td > div': {
                        overflow: 'hidden',
                        height: '55px',
                        background: index % 2 === 0 ? '#FBFBFB' : '#fff',
                        border: index % 2 === 0 ? '1px solid #EDF1F6' : '1px solid transparent',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px 15px',
                      },
                      'td:not(:first-child):not(:last-child) > div': {
                        borderLeft: 'none',
                        borderRight: 'none',
                      },
                      'td:first-child > div': {
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                        borderRight: 'none',
                      },
                      'td:last-child > div': {
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                        borderLeft: 'none',
                      },
                    }}
                  >
                    <CustomTableRow sx={{ width: '2%', maxWidth: '40px' }}>
                      <Box>
                        <Typography color="#0A2540" variant="font18">
                          {index + 1}
                        </Typography>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box sx={{ gap: '6px' }}>
                        <GetFlag country={entry?.user?.citizenship ? entry?.user?.citizenship : entry?.user?.nation} style={{ width: '15px', height: '12px', marginTop: '3px', borderRadius: '3px' }} />

                        <CustomAvatar onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)} src={entry?.user?.avatar} size={24} seed={entry?.user?.name + ' ' + entry.user?.surname} />
                        <CustomButton size="auto" variant="text" onClick={() => navigate(`/athlete/${entry?.user?.id}?tab=overview`)} sx={{ gap: 1 }}>
                          <Typography color="#0A2540" variant="font18">
                            {`${entry?.user?.name?.charAt(0).toUpperCase()}. ${entry?.user?.surname}`}
                          </Typography>
                          {entry?.categoryId?.category?.isDisplayRank && <Typography className={classes.country}>#{entry?.rank ? entry?.rank : 0}</Typography>}
                          <ReactSVG src={`/images/${entry?.user?.gender?.toLowerCase()}.svg`} style={{ width: '16px', height: '16px', color: '#86909F' }} wrapper="span" />

                          {shouldDisplayDupr && entry?.user?.duprRating && <DUPRBox rating={entry?.user?.duprRating} color={getColorByRating(entry?.user?.duprRating)} />}
                        </CustomButton>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box>
                        <Typography className={classes.country} sx={{ background: '#ecf7ec', width: 'max-content', color: '#0d6f30 !important', padding: '2px 8px', borderRadius: '5px' }}>
                          {entry?.categoryId?.category?.name}
                        </Typography>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: 1,
                            maxWidth: 'max-content',
                          }}
                        >
                          <Box sx={{ width: '6px', height: '6px', borderRadius: '4px', backgroundColor: entry?.status === 'found' ? '#5b8fdb' : '#14a541' }}></Box>
                          <Typography className={classes.country} sx={{ textTransform: 'capitalize' }}>
                            {entry?.status === 'active' ? t('Searching...') : entry?.status}
                          </Typography>
                        </Box>
                      </Box>
                    </CustomTableRow>
                    <CustomTableRow>
                      <Box sx={{ width: '100%', height: '100%' }}>
                        <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'end', width: '100%', alignItems: 'center' }}>
                          {entry?.status === 'found' ? (
                            <CustomButton disabled size="medium" variant="outline" sx={{ minWidth: '100px' }}>
                              Partner found
                            </CustomButton>
                          ) : user?.id ? (
                            entry?.user?.id === user?.id ? (
                              <CustomButton
                                size="medium"
                                variant="primary"
                                color="#C41E3A"
                                sx={{ minWidth: '100px' }}
                                onClick={() => {
                                  handleDelete(entry);
                                }}
                              >
                                {t('Delete')}
                              </CustomButton>
                            ) : (
                              <CustomButton
                                size="medium"
                                variant="primary"
                                sx={{ minWidth: '100px' }}
                                disabled={isRegistrationClosed || entry?.cantRegister}
                                onClick={() => {
                                  handleApplyModal(entry);
                                }}
                              >
                                {t('Apply')}
                              </CustomButton>
                            )
                          ) : (
                            <CustomButton
                              size="medium"
                              variant="outline"
                              sx={{ minWidth: '100px' }}
                              onClick={() => {
                                setLoginModalOpen(true);
                              }}
                              disabled={entry?.status === 'found' || entry?.user?.id === user?.id}
                            >
                              {t('Log in to apply')}
                            </CustomButton>
                          )}
                          <InfoModal entryData={selectedItem} onClick={() => handleInfoModal(entry)} setApplyModal={setApplyModal} isDeleting={isDeleting} handleDelete={handleDelete}>
                            <CustomButton
                              shape="circle"
                              size="small"
                              variant="outline"
                              disabled={isRegistrationClosed}
                              color={chroma('#0A254059').darken(2).hex()}
                              sx={{ padding: 0, minHeight: '32px', minWidth: '32px', 'svg > *': { fill: '#0A254059' } }}
                            >
                              <Info sx={{ fontSize: '32px' }} />
                            </CustomButton>
                          </InfoModal>
                        </Box>
                      </Box>
                    </CustomTableRow>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      )}
      <WarningPopup
        openWarningModal={warningModal}
        handleClose={() => setWarningModal(false)}
        handleAction={handleDecline}
        warningTitle="Confirm Decline"
        warningText={
          !user?.id ? (
            <span>
              You need to be logged in to decline a partner. Click
              <CustomButton
                variant="text"
                size="medium"
                sx={{ display: 'inline-flex' }}
                onClick={() => {
                  setWarningModal(false);
                  setLoginModalOpen(true);
                }}
              >
                here
              </CustomButton>
              to log in.
            </span>
          ) : user?.email !== user1 ? (
            'You are not authorized to decline this partner.'
          ) : (
            `Are you sure you want to decline ${user2} as your partner?`
          )
        }
        confirmText="Decline"
        cancelText={user?.email === user1 ? 'Cancel' : 'Close'}
        hideConfirm={user?.email !== user1}
      />
      <WarningPopup
        openWarningModal={acceptWarning}
        handleClose={() => setAcceptWarning(false)}
        handleAction={handleConfirm}
        warningTitle="Confirm partner request"
        confirmColor="green"
        warningText={
          !user?.id ? (
            <span>
              You need to be logged in to accept this request. Click
              <CustomButton
                variant="text"
                size="medium"
                sx={{ display: 'inline-flex' }}
                onClick={() => {
                  setAcceptWarning(false);
                  setLoginModalOpen(true);
                }}
              >
                here
              </CustomButton>
              to log in.
            </span>
          ) : user?.email !== user1 ? (
            'You are not authorized to accept this request.'
          ) : (
            `Are you sure you want to make ${user2} as your partner?`
          )
        }
        confirmText="Accept"
        cancelText={user?.email === user1 ? 'Cancel' : 'Close'}
        hideConfirm={user?.email !== user1}
      />
      <ApplyModal openModal={applyModal} setOpenModal={setApplyModal} categories={categories} entryData={selectedItem} />
    </>
  );
};

export default PartnerSearchListView;
